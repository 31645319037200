<template>
  <div class="grid-container">
    <div class="item0 title">
      <div class="chinese-text">特色品牌体系 • <span style="color: rgb(210, 77, 60);">数字化政府体系</span></div>
      <div class="english-text">Digital Government System</div>
    </div>
    <div class="item1">
      <v-img src="/images/gov/digital-government.png" height="100%" width="100%"
        style="background-color: rgb(244, 244, 244); object-fit: contain;"></v-img>
    </div>
    <div class="item2 text-center">
      <p>{{ paragraphText_1 }}</p>
    </div>
    <div class="item3">
      <v-img src="/images/gov/gov-1.png" cover></v-img>
    </div>
    <div class="item4">
      <v-img src="/images/gov/gov-2.png" cover></v-img>
    </div>
    <div class="item5 text-center">
      <p>{{ paragraphText_2 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GovernmentSection',
  data() {
    return {
      paragraphText_1: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0从2011年到2023年，蓝图信息技术有限公司的发展离不开蓝图自身的过硬技术，为消费者提供高质量服务，也离不开各级政府对中小型创新型企业的大力支持，为蓝图信息技术有限公司提供了运用自身技术，建设数字化政府的机会。',
      paragraphText_2: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0截至目前，蓝图信息技术有限公司已与武汉市人民检察院、武汉市公安局、武汉市质监局、孝感市新政府大楼、孝感招商银行、梅苑派出所公安厅、襄阳国土资源局山桃市卫生局、六指派出所、横店派出所等多地进行交流合作。为我国于政机关、司法机关、公安机关提供高质高量的技术服务，不断为我国数字化政府建设添砖加瓦，以公司自身技术实力，助力国家不断发展壮。',
    };
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 200px);
  grid-template-rows: 160px 180px 180px 180px;
  gap: 0;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  color: white;
  background-image: linear-gradient(to bottom, rgb(209, 66, 57), rgb(213, 98, 64));
  padding: 10px 20px;
}

.text-center>* {
  text-align: left;
  max-width: 100%;
}

.item0 {
  grid-area: 1 / 1 / span 1 / span 6;
}

.title {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chinese-text {
  font-family: '方正正大黑简体';
  font-size: 35px;
}

.english-text {
  font-family: '方正正大黑简体';
  font-size: 22px;
}

.item1 {
  grid-area: 2 / 1 / span 1 / span 1;
}

.item2 {
  grid-area: 2 / 2 / span 1 / span 2;
}

.item3 {
  grid-area: 2 / 4 / span 2 / span 3;
}

.item4 {
  grid-area: 3 / 1 / span 2 / span 3;
}

.item5 {
  grid-area: 4 / 4 / span 1 / span 3;
}
</style>
