<template>
  <v-app>
    <v-app-bar app color="black">
      <template v-slot:prepend>
        <img src="/images/logo-home.png" height="40">
      </template>

      <v-btn text>首页</v-btn>
      <v-btn text>产品中心</v-btn>
      <v-btn text>应用领域</v-btn>
      <v-btn text>合作展示</v-btn>
      <v-btn text>关于我们</v-btn>
      <v-btn text>服务中心</v-btn>
      <v-btn text>新闻中心</v-btn>
      <v-btn text>联系我们</v-btn>

      <template v-slot:append>
        <div
          style="width: 180px; height: 40px; border: 2px solid white; border-radius: 60px; display: flex; flex-direction: row; align-items: center; justify-content: end; padding-right: 5px;">
          <div
            style="background-color: white; border-radius: 50%; height: 33px; width: 33px; display: flex; align-items: center; justify-content: center;">
            <img src="/images/放大镜.png" style="height: 30px; width: 30px;">
          </div>
        </div>
      </template>

    </v-app-bar>

    <v-main class="d-flex justify-center" style="margin-top: 20px;">
      <government-section></government-section>
    </v-main>
  </v-app>
</template>


<script>
import GovernmentSection from '@/components/GovernmentSection.vue';
// import CampusSection from '@/components/CampusSection.vue';
// import BusinessSection from '@/components/BusinessSection.vue';
// import MountainSection from '@/components/MountainSection.vue';

export default {
  name: 'App',
  components: {
    'government-section': GovernmentSection,
    // 'campus-section': CampusSection,
    // 'business-section': BusinessSection,
    // 'mountain-section': MountainSection,
  },
};
</script>

<style></style>

